export interface DefaultNotificationItem {
  // The display name of the notification.
  displayName: string;

  // The description of the notification.
  description: string;

  // The name of the notification.
  notificationName: string;

  // True if the notification is enabled by default for the tenant.
  userEnabled: boolean | null;

  // True if the notification is enabled by default for the customer
  customerEnabled: boolean | null;
}

export interface NotificationItem {
  // The display name of the notification.
  displayName: string;

  // The description of the notification.
  description: string;

  // The name of the notification.
  notificationName: string;

  // True if the notification is enabled by default
  enabled: boolean;
}

const notificationItems: DefaultNotificationItem[] = [
  {
    displayName: "notificationLabelPaymentSuccessful",
    description: "notificationDescriptionPaymentSuccessful",
    notificationName: "TenantPaymentSuccessful",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentFailed",
    description: "notificationDescriptionPaymentFailed",
    notificationName: "TenantPaymentFailed",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentProcessing",
    description: "notificationDescriptionPaymentProcessing",
    notificationName: "TenantPaymentProcessing",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPending",
    description: "notificationDescriptionPaymentPending",
    notificationName: "PaymentPending",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPendingCancelled",
    description: "notificationDescriptionPaymentPendingCancelled",
    notificationName: "PaymentPendingCancelled",
    userEnabled: false,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelPaymentPendingCompleted",
    description: "notificationDescriptionPaymentPendingCompleted",
    notificationName: "PaymentPendingCompleted",
    userEnabled: false,
    customerEnabled: true,
  },

  {
    displayName: "notificationLabelDirectDebitMandateCreated",
    description: "notificationDescriptionDirectDebitMandateCreated",
    notificationName: "DirectDebitMandateCreated",
    userEnabled: false,
    customerEnabled: true,
  },

  {
    displayName: "notificationLabelDirectDebitMandateSetupComplete",
    description: "notificationDescriptionDirectDebitMandateSetupComplete",
    notificationName: "DirectDebitMandateSetupComplete",
    userEnabled: null,
    customerEnabled: true,
  },

  {
    displayName: "notificationLabelDirectDebitMandateCancelled",
    description: "notificationDescriptionDirectDebitMandateCancelled",
    notificationName: "DirectDebitMandateCancelled",
    userEnabled: null,
    customerEnabled: true,
  },
  {
    displayName: "notificationLabelDirectDebitPaymentScheduled",
    description: "notificationDescriptionDirectDebitPaymentScheduled",
    notificationName: "DirectDebitPaymentScheduled",
    userEnabled: false,
    customerEnabled: true,
  },
];

/*
 * Returns the default notification items for a tenant user
 */
export const getDefaultUserNotificationItems = (): NotificationItem[] =>
  notificationItems
    .filter((x) => x.userEnabled !== null)
    .map(
      (item) =>
        ({
          displayName: item.displayName,
          description: item.description,
          notificationName: item.notificationName,
          enabled: item.userEnabled,
        } as NotificationItem)
    );

/*
 * Returns the default notification items for a customer
 */
export const getDefaultCustomerNotificationItems = (): NotificationItem[] =>
  notificationItems
    .filter((x) => x.customerEnabled !== null)
    .map(
      (item) =>
        ({
          displayName: item.displayName,
          description: item.description,
          notificationName: item.notificationName,
          enabled: item.customerEnabled,
        } as NotificationItem)
    );
